html{
  height: 100%;
  body{
    height: 100%;
    background-image: url("../images/bg-landing-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.page-wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content-wrapper{
    background-color: rgba($white, .9);
    text-align: center;
    padding: 30px 30px 45px;
    border-radius: 30px;

    .title-block{
      margin-bottom: 30px;
      h1.t-lvl-1{
        color: $c-main;
        font-family: $font-sec;
      }
    }
    .content-block{
      color: $black;
      font-family: $font-sec;
      a{
        text-decoration: underline;
        &:hover{
          color: $c-main;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 375px){
    .content-wrapper{
      padding: 30px 15px 45px;
      .title-block{
        h1.t-lvl-1{

        }
      }
    }
  }
}