/*==========================================================================
  Teksten
=============================================================================*/
@import url(https://fonts.googleapis.com/css?family=Asap);


$font: 'Montserrat', sans-serif;
$font-sec: 'Asap', sans-serif;

/*==========================================================================
  Kleuren
=============================================================================*/

// THEME

// main
$c-main:           #c0aa6d;
$c-main-light:     lighten( $c-main, 15% );
$c-main-dark:      darken( $c-main, 15% );

// alt
$c-alt:            #81c334;
$c-alt-light:      lighten( $c-alt, 15% );
$c-alt-dark:       darken( $c-alt, 15% );

// accent
$c-accent:         #e9673d;
$c-accent-light:   lighten( $c-accent, 15% );
$c-accent-dark:    darken( $c-accent, 15% );

// sub
$c-sub:            #5389b2;
$c-sub-light:      lighten( $c-sub, 15% );
$c-sub-dark:       darken( $c-sub, 15% );

// COLOR GRADING
$c-white:          #fefefe;
$c-light:          #ededed;
$c-grey:           #bababa;
$c-dark:           #2c2c2c;
$c-black:          #000000;

// COLOR MESSAGES
$c-success:        #8ac759;
$c-error:          #f13542;
$c-warning:        #cf8600;

// COLOR FONT
$c-font:           lighten( $c-black, 10%);
$c-heading:        lighten( $c-black, 20%);
$c-btn:            lighten( $c-black, 20%);

// COLOR SELECTION
$c-selection:      $c-white;
$c-selection-bg:   $c-main;

//COLOR LINKS
$c-link:           $c-main;
$c-link-hover:     $c-main-dark;

// COLOR FOOTER
$c-footer:         $c-font;
$c-footer-bg:      $c-white;

// COLOR LEGAM
$c-legal:          $c-white;
$c-legal-bg:       $c-dark;

/*==========================================================================
  // Screen breakpoint variables
=============================================================================*/
$break-xsmall: 480px;
$break-small: 767px;
$break-medium: 992px;
$break-nav: 1024px;
$break-large: 1200px;
$break-xlarge: 1440px;
