/*==========================================================================
  Basis
=============================================================================*/

$trans: .2s ease-in-out;
$trans-smooth: .5s cubic-bezier(.06,.48,.83,.79);

/* Elementen die een transitie moeten bevatten */

a,
.k1,.k2,.k3,
.t1,.t2,.t3,
.opa,
.tra,
.sluiten,.sluiten:before,.sluiten:after,
input.button,
ul.gfield_checkbox li label:before,
ul.gfield_radio li label:before{
  transition: $trans;
}

/* ==========================================================================
	Custom animaties
============================================================================= */	

/* infaden */

@keyframes infaden {
   0% {  opacity: 0;}
   100% {max-height: 200px; opacity: 1;}
}

/* groeiende lijn */

.lijn{
  position: relative;
  display: inline-block;
  &:after {
    display:block;
    content: '';
    border-bottom: solid 2px #000;  
    transform: scaleX(0); 
    opacity: 0.2; 
    transition: $trans-smooth;
  }
  &:hover:after { transform: scaleX(1); opacity: 1; }
  &.re:after{ transform-origin: 100% 50%; }
  &.li:after{  transform-origin: 0% 50%; }
}

/* Subtiele kleine animaties */
.ani{transition:$trans;}
.ani.li:hover{transform: translate3d(-5px,0,0);}
.ani.re:hover{transform: translate3d(5px,0,0);}
.ani.bo:hover{transform: translate3d(0,-5px,0);}
.ani.on:hover{transform: translate3d(0,5px,0);}
.ani.zo:hover{ transform: scale(1.05);}

/* animaties voor notifications.scss ===================================================*/

/* naar onder duwen en infaden ---------------------------------------*/
@-webkit-keyframes push-onder {0% {  opacity: 0;}100% { opacity: 1; height: auto;}}
@keyframes push-onder {0% {  opacity: 0;}100% { opacity: 1; height: auto;}}

/* gewoon infaden ---------------------------------------*/
@-webkit-keyframes cookienotice {0% {  opacity: 0;}100% { opacity: 1;}}
@keyframes cookienotice {0% {  opacity: 0;}100% { opacity: 1;}}