/*==========================================================================
   popup
=============================================================================*/

.popup{
	position: fixed;
	top: 0; bottom: 0; left: 0; right: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	max-width: none;
	opacity: 0;
	pointer-events: none;
	background-color: rgba(0,0,0,0.5);
	transition:$trans;
	@include z-index(modal);
	&.toon{
		opacity: 1;
		pointer-events: auto;
	}
	.inner{
		position: relative;
		display: block;
		width: 100%;
		max-width: 700px;
		max-height: 100%;
		padding: 45px 40px;
	   overflow: auto;
	   background-color: $c-white;
	   -webkit-overflow-scrolling: touch;
	   .sluiten {
			position: absolute;
			right: 8px;
			top: 8px;
			&:before,&:after{
				background-color: $c-black;
			}
			&:hover:before,&:hover:after{
				background-color: $c-main;
			}
		}
	}
}

@media (max-width : 767px) {
 
.popup .inner{
	padding: 46px 15px 15px;
}

}

/* ==========================================================================
	Vaste meldingen
============================================================================= */

/* melding-cookie ===================================================*/

.melding-cookie{
	position: fixed;
	bottom: 15px;
	left: 15px;
	padding: 20px 20px;
	background-color: #f5f5f5;
	box-shadow: 0 0px 40px 0px rgba(0,0,0,0.2);
	width: 90%;
	z-index:20;
	font-size: 14px;
	line-height: 1.3;
	max-width: 345px;
	transition: all .5s;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-delay: 1s;
	animation-name: cookienotice;
	p:not(:first-of-type){
		margin-bottom: 15px;
	}
	a{
		border-bottom: 1px solid;
		transition: all .2s;
		color: #3c3c3b;
		&:hover{
			opacity: .7;
		}
	}
	.cookie-actions{
		margin-top: 20px;
		.cookie-accept{
			display: inline-block;
			font-weight: bold;
			padding: 8px 18px;
			color: #ffffff;
			border-radius: 5px;
			cursor: pointer;
			background-color: #7cc42e;
			transition: all .2s;
			&:hover{
				opacity: .7;
			}
		}
		.cookie-hide {
			display: inline-block;
			margin-left: 15px;
			cursor: pointer;
			color: #777;
			transition: all .2s;
			&:hover{
				opacity: .7;
			}
		 }
	}
}

/* melding-boven ===================================================*/

.melding-boven{
	position: relative;
	height: 0;
	animation-duration: 1s;
	animation-fill-mode: both;
  	animation-delay: 1s;
	animation-name: push-onder;
	.container{
		.row{
			padding: 10px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: bold;
			.inhoud{
				padding-right: 10px;
			}
			.sluiten{
				&:hover{
					opacity: .7;
				}
				&:before,&:after{
					background-color: $c-white;
				}
			}
		}
	}
}

/* melding-popup ===================================================*/

.melding-popup{
	transition: all .5s;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-delay: 1s;
	animation-name: cookienotice;
	pointer-events: auto;
}
