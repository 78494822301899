/*==========================================================================
  Koppen algemeen
=============================================================================*/

.btn,.btn:visited{
	display: inline-block;
	cursor: pointer;
	font-weight: normal;
	line-height: 1;
	text-transform: none;
	min-width: 120px;
	text-align: center;
	font-size: 14px;
	padding: 15px 30px;
	border-radius: 0px;
	border: 2px solid;
	&:hover,&:visited:hover{
		opacity: .8;
	}
	&.btn-ghost{
		background-color: transparent;
		&:hover,&:visited:hover{
			opacity: 1;
		}
	}
}

/* Volle knoppen ===============================================*/

.btn-main,.btn-main:visited {
	color: $c-white;
	background-color: $c-main;
	border-color: $c-main;
}

.btn-alt,.btn-alt:visited {
	color: $c-white;
	background-color: $c-alt;
	border-color: $c-alt;
}

.btn-accent,.btn-accent:visited {
	color: $c-white;
	background-color: $c-accent;
	border-color: $c-accent;
}

.btn-sub,.btn-sub:visited {
	color: $c-white;
	background-color: $c-sub;
	border-color: $c-sub;
}

.btn-black,.btn-black:visited {
	color: $c-white;
	background-color: $c-black;
	border-color: $c-black;
}

.btn-white,.btn-white:visited {
	color: $c-black;
	background-color: $c-white;
	border-color: $c-white;
}

/* Ghost knoppen ===============================================*/

.btn-ghost.btn-main,.btn-ghost.btn-main:visited {
	color: $c-main;
	border-color: $c-main;
	&:hover{
		color: $c-white;
		background-color: $c-main;
	}
}

.btn-ghost.btn-alt,.btn-ghost.btn-alt:visited {
	color: $c-alt;
	border-color: $c-alt;
	&:hover{
		color: $c-white;
		background-color: $c-alt;
	}
}

.btn-ghost.btn-accent,.btn-ghost.btn-accent:visited {
	color: $c-accent;
	border-color: $c-accent;
	&:hover{
		color: $c-white;
		background-color: $c-accent;
	}
}

.btn-ghost.btn-sub,.btn-ghost.btn-sub:visited {
	color: $c-sub;
	border-color: $c-sub;
	&:hover{
		color: $c-white;
		background-color: $c-sub;
	}
}

.btn-ghost.btn-black,.btn-ghost.btn-black:visited {
	color: $c-black;
	border-color: $c-black;
	&:hover{
		color: $c-white;
		background-color: $c-black;
	}
}

.btn-ghost.btn-white,.btn-ghost.btn-white:visited {
	color: $c-white;
	border-color: $c-white;
	&:hover{
		color: $c-black;
		background-color: $c-white;
	}
}

/* ==========================================================================
   Knop met pijl
============================================================================= */

.pijl{
	position: relative;
	cursor: pointer;
	font-weight: bold;
	font-size: 12px;
	display: inline-block;
}

.pijl.vo:before,
.pijl.ac:after{
	@include fa-icon;
	@extend .far;
}

.pijl.vo:before{margin-right: 10px;}
.pijl.ac:after{margin-left: 10px;}

.pijl.vo.re:before,.pijl.ac.re:after{content:fa-content($fa-var-arrow-right);}
.pijl.vo.li:before,.pijl.ac.li:after{content:fa-content($fa-var-arrow-left);}

.pijl.vo.re:hover:before, .pijl.ac.re:hover:after{
	transform: translate3d(3px,0,0);
	color: inherit;
}

.pijl.vo.li:hover:before, .pijl.ac.li:hover:after{
	transform: translate3d(-3px,0,0);
	color: inherit;
}

/* hamburger ==============================================*/

.hamburger{
	position: relative;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
  .lijnen {
		transform-origin: center;
		position: relative;
		width: 100%;
		height: 3px;
		display: block;
		background-color: $c-font;
		&:before,&:after{
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background-color: $c-font;
		}
		&:before {
        top: -10px;
      }
      &:after {
        bottom: -10px;
      }
  }
  &.actief{
    .lijnen {
      transform: rotate(45deg);
      &:before {
        transform: rotate(90deg);
        top: 0;
      }
      &:after {
        transform: rotate(90deg);
        bottom: 0;
      }
    }
  }
}

/* ==========================================================================
   Back to top
============================================================================= */

.backtotop{
	position: fixed;
	cursor: pointer;
	width: 36px;
	height: 36px;
	right: 10px;
	bottom: 10px;
	opacity: 0;
	transition:all 0.15s ease-in-out;
	box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
	background-color: $c-main;
	@include z-index(top);
	&:before{
		@extend .far;
		content:fa-content($fa-var-chevron-up);
		font-size: 18px;
		color: $c-white;
		transition:all 0.15s ease-in-out;
	}
	&:hover{ 
		background-color: $c-main-light; 
		&:before{ 
			color: $c-white; 
		}
	}
	&.toon{
		opacity: 1;
	}
}

@media (max-width : 1150px) {.backtotop.fix{bottom: 52px;}}

/* ==========================================================================
	Social links
============================================================================= */

.social{
	margin: -5px;
	a{
		margin: 5px;
		i{
			position: relative;
			display: flex;
			font-style: normal;
			font-size: 12px;
			border-radius: 50%;
			height: 25px;
			width: 25px;
			color: $c-white;
			background-color: $c-font;
			@extend .fab;
			&.facebook:before{content:fa-content($fa-var-facebook-f);}
			&.linkedin:before{content:fa-content($fa-var-linkedin-in);}
			&.twitter:before{content:fa-content($fa-var-twitter);}
			&.instagram:before{content:fa-content($fa-var-instagram);}
			&.pinterest:before{content:fa-content($fa-var-pinterest);}
			&.mail:before{@extend .fas;content:fa-content($fa-var-envelope);}
		}
		&:hover{
			i{
				color: $c-font;
				background-color: $c-main;
			}
		}
	}
}

/* ==========================================================================
   Sluiten
============================================================================= */

.sluiten {
	position: relative;
	cursor: pointer;
	display: block;
	width: 30px;
	height: 30px;
	&:before, &:after {
		content: "";
		position: absolute;
		height: 20px;
		width: 2px;
		left: 14px;
		top: 5px;
	}
	&:before {
	  	transform: rotate(45deg);
	}

	&:after {
	  	transform: rotate(-45deg);
	}
}

/*==========================================================================
  contact icons
=============================================================================*/

.ct{ 
	position: relative; 
	display: inline-block;
	&:before{
		color: inherit;
		font-size: 12px;
		position: relative;
		display: inline-block;
		margin-right: 7px;
		@extend .fas;
	}
	&.adres:before{content:fa-content($fa-var-map-marker);}
	&.tel:before{content:fa-content($fa-var-phone);}
	&.mail:before{content:fa-content($fa-var-envelope);}
}

@media (min-width : 1025px) {a[href^="tel:"]{pointer-events:none;}}