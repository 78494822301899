/* ==========================================================================
   Wrappers, sections en containers
============================================================================= */

body.noscroll{
	overflow-y: hidden;
}

#site-wrapper{
	position: relative;
	overflow: hidden;
}

#site-main{
	position: relative;
}

section{ 
	margin: 100px 0; 
	@include media-breakpoint-down(md) {
		margin: 50px 0;
	}
}

.container {
	padding: 0;
	max-width: 1000px;
	width: 90%;
}