/* ==========================================================================
	Algemeen
============================================================================= */

input[type="text"],
input[type="email"], 
input[type="url"], 
input[type="tel"],
input[type="number"],
input[type="date"],
select, 
textarea{
	width: 100%;
	display: block;
	padding: 8px 8px;
	font-size: 14px;
	height: 46px;
	border-style:solid;
	border-width: 0px;
	border-radius: 0px;
	outline: none;
	color: $c-font;
	background-color: $c-light;
	border-color: $c-font;
	outline: none;
	-webkit-appearance: none;
}

select{
	-webkit-appearance: none;
}

textarea{
	width: 100%!important;
	height: 200px;
	min-height: 100px;
}

/* button / submit ---------------------------------------*/

input.button{
	@extend .btn;
	@extend .btn-main;
	outline: none;
	-webkit-appearance: none;
}

/* Placeholders ---------------------------------------*/

::-webkit-input-placeholder { color: $c-grey; }
:-ms-input-placeholder { color: $c-grey; }

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder { opacity: 0.4 }
input:hover:-ms-input-placeholder, textarea:hover:-ms-input-placeholder { opacity: 0.4 }

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder { color: transparent; }
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder { color: transparent; }

/* ==========================================================================
	Gravity forms
============================================================================= */

.gform_wrapper{

	form{
		position: relative;
		max-width: 100%;

		&.clean .gfield_label, 
		&.clean .validation_message, 
		.gfield.gform_validation_container,
		.gform_wrapper .hidden_label > label{ 
			display: none; 
		}

		.gfield_label{
			display: block;
			font-weight: bold;
			margin-bottom: 5px;
		}

		.hidden_label{
			.gfield_label{
				display: none;
			}
		}

		.gfield.gform_hidden{
			padding: 0;
		}

		/* error ---------------------------------------*/

		.validation_error {
			font-size: 14px;
			padding: 14px 20px;
			margin-bottom: 15px;
			color: $c-white;
			background-color: $c-error;
		}

		.gfield_error{
			input,select,textarea{
				border-bottom: 2px solid $c-error;
			}
		}

		.gfield_description.validation_message{
			font-size: 12px; 
			margin-top: 5px;
			color: $c-error;
		}

		/* footer en spinner ---------------------------------------*/

		.gform_footer {
			margin-top: 20px;
			display: flex;
			align-items: center;
		}

		.gform_ajax_spinner {margin-left: 8px;}

		/* select ---------------------------------------*/

		.ginput_container_select{
			position: relative;
			&:after{
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translate(0, -50%);
				font-size: 14px;
				margin-left: 6px;
				@extend .far;
				content:fa-content($fa-var-chevron-down);
			}
		}

		/* Checkboxes & Radios ---------------------------------------*/
		.ginput_container_checkbox,
		.ginput_container_radio,
		.ginput_container_consent{
			label {
				position: relative;
				padding: 0 0 0 20px;
				cursor: pointer;
				display: inline-block;
				/* binnenste */
				&:before{
					content: "";
					position: absolute;
					top: 3px;
					left: 0px;
					z-index: 9;
					opacity: 0;
					background-color: $c-grey;
					width: 13px;
					height: 13px;
					transform: scale(.4);
				}
				/* buitenste */
				&:after{
					content: "";
					position: absolute;
					top: 3px;
					left: 0;
					background-color: $c-white;
					border: solid 2px $c-grey;
					height: 13px;
					width: 13px;
				}
			}
			input{
				display: none;
				&:checked + label{
					&:before{
						opacity: 1;
					}
				}
			}
		}

		.ginput_container_checkbox,
		.ginput_container_radio{
			li{
				&:not(:last-of-type){
					margin-bottom: 5px;
				}
			}
		}

		.ginput_container_consent{
			label {
				padding: 1px 0 0 20px;
				font-size: 12px;
				a{
					text-decoration: underline;
					&:hover{opacity: .8;}
				}
			}
			.gfield_required{display: none;}
		}

		/* radio */
		.gfield_radio li{
			label:after,input[type="radio"]:checked + label:before {
			  border-radius: 50%;
			}

		}

		/* Formulier template ===============================================*/

		$formgutter: 8px;

		ul.gform_fields{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: -$formgutter;
			> li{width: 100%; padding: $formgutter;}
			> li.kwart{width: 25%;}
			> li.half{width: 50%;}
			> li.eenderde{width: 33.333%;}
			> li.tweederde{width: 66.666%;}
		}

		@include media-breakpoint-down(sm) { ul.gform_fields li.gfield{ width:100%!important;} }

	}
}

/* succes ---------------------------------------*/

.gform_confirmation_message{
	font-size: 14px;
	padding: 14px 20px;
	color: $c-white;
	background-color: $c-success;
}