html, body, div, span, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,a, code, strong, var,b, u, i, dl, dt, dd, ol, ul, li, form, label, table, caption, tr, th, td,article, canvas, embed, figure, figcaption, footer, header, menu, nav, section, audio, video {margin: 0;padding: 0;border: 0;font-size: 100%;vertical-align: baseline;outline: none!important;}
article, aside, details, figcaption, figure, footer, header, menu, nav, section {display: block;}
body{overflow-x:hidden;}
iframe[name='google_conversion_frame'] { height: 0 !important;width: 0 !important; line-height: 0 !important; font-size: 0 !important;margin-top: -13px;}
a{text-decoration: none;}
ul {list-style: none;padding: 0;margin: 0;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: '';content: none;}
table {border-collapse: collapse;border-spacing: 0;}
img{-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;backface-visibility: hidden;margin-bottom: 0!important; vertical-align: top;}
a img{border:none!important;outline: none!important;border-width: 0!important;}
*,*:before,*:after, p, li, ul, img, h1, h2, h3, h4, h5, a, b, span, strong {-webkit-font-smoothing: antialiased;box-sizing:border-box;}
strong {font-weight: bold;}
textarea,input,select{font-family: inherit;}
button{outline: none!important;background-color: transparent;border: none;margin-left: auto;}
img{max-width: 100%;}
section:before,section:after, .container:before,.container:after{content: ""; display: table;}
section:after, .container:after{clear: both;}
@include media-breakpoint-down(md) {.col {flex-basis: inherit;}}