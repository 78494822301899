/* ==========================================================================
   Helpers
============================================================================= */

/* guidelines ---------------------------------------*/
/*
section{border: 2px solid #90d3d5;}
.container{border: 2px solid #9dc172;}
.row{border: 2px solid #ee8a99;}
*/

/* fullbg ---------------------------------------*/
.fullbg{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* fullcover ---------------------------------------*/
.fullcover{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

/* hidden ---------------------------------------*/
.keep-hidden,.hidden{display: none!important;}

/* algemene helpers ---------------------------------------*/
.dum, .dum *{color: red!important;}
.nobr br{ display: none !important;}
.nop{padding: 0!important;}
.nom{margin: 0!important;}
.cen{text-align: center;}

/* flex helpers ---------------------------------------*/
.flex { 
	display: flex; 
	justify-content: space-between; 
	flex-wrap: wrap; 
}

.flex-top { align-items: flex-start; }
.flex-middle { align-items: center; }
.flex-bottom { align-items: flex-end; }

.flex-left { justify-content: flex-start; }
.flex-center { justify-content: center; }
.flex-right { justify-content: flex-end; }

.flex-col { flex-direction: column; }

/* spacer ---------------------------------------*/
.spacer{
	display: flex;
	flex-wrap: wrap;
	margin: -10px;
	> .item,.knop,div,.btn,a{
	  display: inline-block;
	  margin: 10px;
	}
	&.narrow{
		margin: -5px;
		> .item,.knop,div,.btn,a{
		  margin: 5px;
		}
	}
}

/* mobiele cols ---------------------------------------*/
@include media-breakpoint-down(md) {
	section{
		.container{
			.row{
				.col{
					&:not(:last-of-type){
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}

/* nomob ---------------------------------------*/
@include media-breakpoint-down(md) {.nomob{display: none;}}
@include media-breakpoint-up(lg) {.mob{display: none;}}