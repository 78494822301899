.uni{

	h1{ @extend .t-lvl-1; margin: 25px 0 15px;}
	h2{ @extend .t-lvl-2; margin: 20px 0 10px;}
	h3{ @extend .t-lvl-3; margin: 15px 0 8px;}
	h4{ @extend .t-lvl-4; margin: 10px 0 5px;}

	.t-lvl-1,.t-lvl-2,.t-lvl-3,.t-lvl-4,.t-lvl-5,.t-lvl-6,.t-lvl-7{
		&:first-child{
			margin-top: 0;
		}
	}

	a{ color: $c-main; } 
	a:visited{ color: $c-main; } 
	a:hover{ color: $c-main; }

	/* lijsten ---------------------------------------*/

	ul, ol{
		margin: 20px 0;
		&:first-child{margin-top: 0;}
		&:last-child{margin-bottom: 0;}
	}

	ul li:last-of-type, ol li:last-of-type{margin: 0;}

	ul>li,ol>li {
		position: relative;
		list-style-type: none;
		padding-left: 20px;
		margin: 0 0 10px;
	}

	/* ol */

	ol{
		list-style: decimal;
		list-style-position: inside;
		> li {
			counter-increment: tel;
			&:before {
				content: counter(tel) ".";
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	/* ul */

	ul{
		>li{
			&:before{
				content:"";
				width: 6px;
				height: 6px;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 7px;
				display: inline-block;
				background: $c-main;
			}
		}
	}
	.vink{
		> ul{
			>li{
				&:before{
					@include fa-icon;
					@extend .far;
					content:fa-content($fa-var-check);
					width: 0;
					height: 0;
					border-radius: 0;
					position: absolute;
					left: 0;
					top: 2px;
					display: inline-block;
					font-size: 14px;
					font-weight: normal;
					color: $c-main;
					background: none;
				}
			}
		}
	}

}