// ==========================================================================
//                              MIXINS
// ==========================================================================
// Absolute center position
@mixin pos-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// Overlay element
@mixin overlay($op) {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $c--black;
  opacity: $op;
  z-index: 0;
}

// Background image
@mixin bg($h: 50%, $v: 50%) {
  background-size: cover;
  background-position: $h $v;
}

// Make section with responsive spacing
@mixin make-section(
  $top: map-get($spacing, xxl),
  $bottom: map-get($spacing, xxl)
) {
  padding: $top 0 $bottom;
  @include media-breakpoint-down(lg) {
    padding: $top / 1.5 0 $bottom / 1.5;
  }
  @include media-breakpoint-down(md) {
    padding: $top / 1.75 0 $bottom / 1.75;
  }
  @include media-breakpoint-down(sm) {
    padding: $top / 2 0 $bottom / 2;
  }
}

@mixin make-intro(
  $width: 800px,
  $title-spacing: map-get($spacing, sm),
  $spacing: map-get($spacing, md)
) {
  text-align: center;
  margin-bottom: $spacing;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(lg) {
    margin-top: $spacing / 1.5;
  }
  @include media-breakpoint-down(md) {
    margin-top: $spacing / 1.75;
  }
  @include media-breakpoint-down(sm) {
    margin-top: $spacing / 2;
  }

  .sub {
    max-width: $width;
    font-size: $fz--sub;
  }

  .title + .sub {
    margin-top: $title-spacing;

    @include media-breakpoint-down(lg) {
      margin-top: $title-spacing / 1.5;
    }
    @include media-breakpoint-down(md) {
      margin-top: $title-spacing / 1.75;
    }
    @include media-breakpoint-down(sm) {
      margin-top: $title-spacing / 2;
    }
  }
}

@mixin make-footer($spacing: $s--base * 1.5) {
  flex-direction: column;
  align-items: center;
  margin-top: $spacing;

  @include media-breakpoint-down(md) {
    margin-top: $spacing / 1.5;
  }
  @include media-breakpoint-down(sm) {
    margin-top: $spacing / 1.75;
  }
  @include media-breakpoint-down(xs) {
    margin-top: $spacing / 2;
  }
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
  object-fit: $fit;
  @if $position {
    object-position: $position;
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
  } @else {
    font-family: "object-fit: #{$fit}";
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// Responsive ratio
@mixin ratio($h: 50%) {
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-bottom: $h;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include object-fit(cover);
  }
}


@mixin img-bg() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }
}


//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $min-vw [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $max-vw [900px] - Stop scaling font larger at this screen resolution
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type(
  $min-font-size: 14px,
  $max-font-size: 21px,
  $min-vw: 540px,
  $max-vw: 1024px
) {
  font-size: $min-font-size;
  @media screen and (min-width: $min-vw) {
    font-size: calc(
      #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
        ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
    );
  }
  @media screen and (min-width: $max-vw) {
    font-size: $max-font-size;
  }
}

//- Mixin: Material Shadow
///
/// Box shadows based on 5 Material Design presets;
///
/// @param {variable} $z-depth [1] - shadow distance on z-axis
/// @param {variable} $strength [1] - multiplier for shadow strength/darkness
/// @param {variable} $color [1] - shadow color
/// @group layout
/// @example
///   div {
///     @include material-shadow(3);
///   }
/// @link https://codepen.io/dbox/pen/RawBEW

@mixin material-shadow($z-depth: 1, $strength: 1, $color: black) {
  @if $z-depth == 1 {
    box-shadow: 0 1px 3px rgba($color, $strength * 0.14),
      0 1px 2px rgba($color, $strength * 0.24);
  }
  @if $z-depth == 2 {
    box-shadow: 0 3px 6px rgba($color, $strength * 0.16),
      0 3px 6px rgba($color, $strength * 0.23);
  }
  @if $z-depth == 3 {
    box-shadow: 0 10px 20px rgba($color, $strength * 0.19),
      0 6px 6px rgba($color, $strength * 0.23);
  }
  @if $z-depth == 4 {
    box-shadow: 0 15px 30px rgba($color, $strength * 0.25),
      0 10px 10px rgba($color, $strength * 0.22);
  }
  @if $z-depth == 5 {
    box-shadow: 0 20px 40px rgba($color, $strength * 0.3),
      0 15px 12px rgba($color, $strength * 0.22);
  }
  @if ($z-depth < 1) or ($z-depth > 5) {
    @warn "$z-depth must be between 1 and 5";
  }
}

/* Z-indexes ===================================================*/

$z-index: (
  modal              : 10,
  nav                : 5,
  footer             : 4,
  
  top                : 2,
  above              : 1,
  equal              : 0,
  sub                : -1,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
