/* video responsive ---------------------------------------*/

.vidresp { 
	position: relative; 
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;
	& iframe,& object,& embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* playknop ---------------------------------------*/

.playknop{
	position: relative; 
	cursor: pointer;
	background-color:$c-main;
	@include z-index(above);
	width: 50px;
	height: 50px;
	&:after{
		content: "";
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		pointer-events: none;
		margin-left: -8px;
		margin-top: -15px;
		width: 22px;
		height: 30px;
		border-top: 15px solid transparent;
		border-left: 22px solid;
		border-bottom: 15px solid transparent;
		transition:all 0.2s ease-in-out;
		border-left-color: $c-white;
	}
	&:hover{
		background-color:$c-alt;
		&:after{
			border-left: 22px solid;
			border-left-color: $c-white;
		}
	}
}