/* algemeen ===================================================*/

body{ 
	font-family: $font; 
	color: $c-font;
	line-height: 1.5;
	@extend.t-lvl-6;
}

p:not(:last-of-type){margin-bottom: 20px;}

::selection {
  color: $c-selection;
  background-color: $c-selection-bg;
}

/* links ---------------------------------------*/

a{ color: $c-font; } 
a:visited{ color: $c-font; }

.poptrig,.knop{cursor: pointer;}
.opa:hover{opacity: .7;}
.op a:hover{opacity: .7;}

/* opmaakhulp ---------------------------------------*/

.bold{font-weight: bold;}
.upp{text-transform: uppercase;}

/* text-levels  ----------------------------------- */

h1,h2{
	font-family: $font;
}

.t-lvl-1{ font-size: 40px; line-height: 1.3;}
.t-lvl-2{ font-size: 34px; line-height: 1.3;}
.t-lvl-3{ font-size: 28px;}
.t-lvl-4{ font-size: 24px;}
.t-lvl-5{ font-size: 18px;}
.t-lvl-6{ font-size: 16px;}
.t-lvl-7{ font-size: 12px;}

@include media-breakpoint-down(md) {

	.t-lvl-1{ font-size: 26px;}
	.t-lvl-2{ font-size: 24px;}
	.t-lvl-3{ font-size: 20px;}
	.t-lvl-4{ font-size: 18px;}
	.t-lvl-5{ font-size: 16px;}
	.t-lvl-6{ font-size: 14px;}
	.t-lvl-7{ font-size: 12px;}

}